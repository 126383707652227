import React, { useState, useEffect, useContext, useCallback, memo } from "react";
import {
  add,
  applySpec,
  assoc,
  flip,
  has,
  identity,
  isEmpty,
  map,
  not,
  pipe,
  prop,
  reduce,
  reject,
  values,
} from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { Context } from "../context/PackagesContext";
import { Types as PageTypes } from "../context/page.reducer";
import { Types as DataTypes } from "../context/data.reducer";
import { Button, Input, SelectPagination } from "@foris/avocado-ui";
import { IParams } from "../../../../models/IParams";
import { populationsQuery } from "../graphql/populations.query";
import css from "./packages.module.scss";
import cx from "classnames";
import { Population } from "@models/ISchema";
import TermSelect from "@modules/vacancies/Groups/TermSelect";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import { IOption } from "@common/components/Select/Select";
import { DataReducerType } from "../context/types";

interface ISelectedPopulation {
  index: number;
  value: string;
  level: number;
  self: Population;
}

interface Props {
  isLoading?: boolean;
  onSubmit: (dryRun: boolean, skipValidations: boolean, data?: DataReducerType) => void;
  onCancel?: () => void;
}

const CreationForm = (props: Props) => {
  const { onSubmit, isLoading = false, onCancel } = props;
  const client = useApolloClient();
  const { state, dispatch } = useContext(Context);
  const { origin, scenario }: IParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString?.parse(location?.search);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [submitAndGoBack, setSubmitAndGoBack] = useState(false);
  const [selectedPopulation, setSelectedPopulation] = useState<ISelectedPopulation>(null);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [packageName, setPackageName] = useState("");

  const selectorLabel = (population: Population) => {
    if (!population) return "";

    const strongWrapper = (value: string | number) => <strong>{value}</strong>;

    const campus = strongWrapper(population?.campus?.code);
    const program = strongWrapper(population?.program?.code);
    const curriculum = strongWrapper(population?.curriculum);
    const level = strongWrapper(population?.level);
    const modality = strongWrapper(population?.modality?.code);
    const department = strongWrapper(population?.program?.department?.code);
    const shift = strongWrapper(population?.shift?.code);

    return (
      <div>
        <p className={css.selector__input__population__label_top}>
          SE: {campus} | CA: {program} | CU: {curriculum} | NI: {level}
        </p>
        <p className={css.selector__input__population__label_bottom}>
          ES: {department} | MO: {modality} | JO: {shift}
        </p>
      </div>
    );
  };

  /**
   * Set "EDITION" as the active page so the context take us to the edition page.
   */
  const navigateToEdition = () => {
    dispatch({ type: PageTypes.SetActivePage, payload: "EDITION" });
  };

  const handlePackageCreation = () => {
    dispatch({
      type: DataTypes.AddCreation,
      payload: {
        population: selectedPopulation?.self,
        termId: selectedTerm,
        linkId: state?.data?.link?.id || undefined,
        packageName,
      },
    });
    setSubmitAndGoBack(true);
  };

  /**
   * Reject the populations that have already been created
   *
   * @param populations - Population[]
   * @return Population[]
   */
  const selectablePopulations = (populations: Population[]): Population[] => {
    const createdPopulationCodes = pipe(
      values,
      map(prop<Population>("population")),
      reduce((acc, { code }) => assoc(code, true, acc), {}),
    )(state?.data?.creations);
    return reject(pipe(prop<any>("code"), flip(has)(createdPopulationCodes)), populations);
  };

  /**
   * If a creation was added, submit a `dryRun` `packageCrud` request and
   * return to the `EDITION` view.
   */
  useEffect(() => {
    if (not(isEmpty(state?.data?.creations)) && submitAndGoBack) {
      setSubmitAndGoBack(false);
      onSubmit(true, true, state?.data);
      navigateToEdition();
    }
  }, [state?.data?.creations]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const queryParams = {
          query: populationsQuery,
          variables: {
            termId: selectedTerm,
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              fields: {
                campusId: selectedCampus
                  ? {
                      eq: selectedCampus,
                    }
                  : undefined,
                modalityId: selectedModality
                  ? {
                      eq: selectedModality,
                    }
                  : undefined,
                programId: selectedProgram
                  ? {
                      eq: selectedProgram,
                    }
                  : undefined,
              },
              pagination: { page, size, searchTerm },
            },
          },
        };

        const { data } = await client.query(queryParams);
        const { pageInfo, items } = data?.cube?.complementedPopulations ?? {};

        const options = map<Population, Population>(
          applySpec({
            label: selectorLabel,
            value: prop("id"),
            index: pipe(prop<number, "packageIndex">("packageIndex"), add(1)),
            self: identity,
          }),
          selectablePopulations(items ?? []),
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, selectedTerm, selectedCampus, selectedModality, selectedProgram],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = searchTerm === newSearchTerm ? page + 1 : 1;

    setSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(searchTerm, newSearchPage);
    dispatch({ type: DataTypes.SetPopulations, payload: options ?? [] });

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  const resetPopulationOptions = () => {
    setSearchTerm("");
    setSelectedPopulation(null);
    dispatch({ type: DataTypes.SetPopulations, payload: [] });
  };

  const handleTermSelect = (term = "") => {
    resetPopulationOptions();
    setSelectedTerm(term);
  };

  const termKey = `${selectedTerm}${selectedCampus}${selectedModality}${selectedProgram}`;
  const isRequiredFieldsSelected = !!(
    selectedTerm &&
    (selectedCampus || selectedModality || selectedProgram)
  );
  const linkCampus = state?.data?.link?.course?.curriculum?.program?.campus;
  const isGlobalCampus = !!linkCampus?.isGlobal;

  return (
    <section className={css.main}>
      <div className={cx(css.selector, css.selector_control)}>
        <TermSelect
          className={css.term}
          sendValues={({ term }) => handleTermSelect(term ?? "")}
          defaultTerm={state?.data?.link?.bundle?.term}
          isDisabled={!!state?.data?.link?.bundle?.term?.id}
        />
      </div>

      <div className={cx(css.selectorGroup)}>
        <CampusSelect
          label
          onCallback={(campus: IOption) => setSelectedCampus(campus?.value || "")}
          defaultCampus={!isGlobalCampus && linkCampus}
          isDisabled={!!linkCampus?.id && !isGlobalCampus}
        />
        <ModalitySelect
          label
          shouldHasDepartment={false}
          onCallback={(modality: IOption) => setSelectedModality(modality?.value || "")}
        />
        <ProgramSelect
          label
          campus={selectedCampus}
          modality={selectedModality}
          shouldHaveFilters={false}
          filterBy="modality"
          onCallback={(program: IOption) => setSelectedProgram(program?.value || "")}
        />
      </div>

      <div className={css.selector}>
        <SelectPagination
          key={termKey}
          className={css.selector__input__population}
          isDisabled={!isRequiredFieldsSelected}
          label={"Agregar población"}
          value={selectedPopulation}
          debounceTimeout={500}
          placeholder={"Buscar población"}
          onChange={setSelectedPopulation}
          loadOptions={loadOptions}
          onMenuClose={() => {
            if (searchTerm === "") return;

            setSearchTerm("");
            setPage(0);
          }}
        />
      </div>
      <div className={css.selector}>
        <Input
          disabled={true}
          error={false}
          label={"Numerador"}
          placeholder={"Automático"}
          type={"number"}
          value={Boolean(selectedPopulation) ? selectedPopulation?.index : ""}
        />
      </div>
      <div className={css.packageName}>
        <Input
          disabled={!selectedPopulation || !selectedPopulation?.index}
          label="Paquete"
          placeholder="Ingresa un nombre para el paquete"
          type="text"
          value={packageName}
          onChange={e => setPackageName(e.target.value)}
        />
      </div>
      <section className={css.buttons_section}>
        <Button
          className={cx(css.selector__button)}
          disabled={isLoading}
          variant="outline"
          onClick={() => (onCancel ? onCancel() : navigateToEdition())}
        >
          Cancelar
        </Button>
        <Button
          disabled={!(selectedPopulation && packageName) || isLoading}
          className={cx(css.selector__button)}
          onClick={handlePackageCreation}
        >
          Crear Paquete
        </Button>
      </section>
    </section>
  );
};

export default memo(CreationForm);
