import React, { useEffect, useState, useContext } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { Instructor, CubeQuery } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/switchPage.reducer";
import FormItem from "../../../components/FormEdit/FormItem";
import SimpleTableSearch from "../../../components/Instructor/SimpleTable";
import Autocomplete from "../../../components/Instructor/Autocomplete";
import { useDebounce } from "../../../hooks/useDebounce";
import { ContextApp } from "@config/Context/contextApp";
import { instructorSearch } from "../../../graphql/instructor.query";
import { Checkbox, Tooltip } from "@foris/avocado-ui";
import UsableLabel from "@common/components/Autocomplete/usabe-label";
import css from "./instructor.module.scss";

interface InstructorSearchProps {
  disabled?: boolean;
}

interface InstructorOption extends Instructor {
  label: string;
}

const getFormattedLabel = (option: InstructorOption) => {
  return <UsableLabel label={`${option.code} - ${option.name}`} isUsable={option?.usable} />;
};

const InstructorSearch = ({ disabled = false }: InstructorSearchProps) => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = useContext(ContextApp);
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const shouldFilterInstructors = state?.page?.active?.advanceSearchFilterInstructor;
  const setShouldFilterInstructors = (val: boolean) =>
    dispatch({
      type: Types.SetFilterInstructors,
      payload: val,
    });

  const debouncedSearch = useDebounce(valueSearch, 500);

  const someDeletedSessionIsSelected = R.pipe(
    R.map(R.propOr("-", "id")),
    R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
  )(state?.form?.selectedSessions);

  const serializeOptions = (isntructors: Instructor[]) => {
    const instructorOptions = [];

    if (isntructors.length === 0) return instructorOptions;

    isntructors.forEach(instructor => {
      instructorOptions.push({
        ...instructor,
        label: `${instructor.code} - ${instructor.name}`,
        value: instructor.id,
        isDisabled: !instructor?.usable,
      });
    });
    return instructorOptions;
  };

  const queryOptions = (value: string) => {
    return {
      query: instructorSearch,
      variables: {
        originId: origin,
        scenarioId: scenario,
        filter: {
          fields: shouldFilterInstructors
            ? {
                courseId: {
                  eq: state?.link?.info?.course?.id,
                },
              }
            : undefined,
          pagination: {
            page: 1,
            size: 20,
            searchTerm: value || undefined,
          },
        },
      },
    };
  };

  const getDataOptions = async (value: string) => {
    try {
      setLoading(true);
      if (value !== "") {
        const query = queryOptions(value);
        const { data } = await client.query(query);
        const dataQuery: CubeQuery = data?.cube;
        const insOptions = serializeOptions(dataQuery.instructorsAdvancedSearch.items);
        setOptions(insOptions);
        setLoading(false);
      }
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearch) {
      setLoading(true);
      getDataOptions(debouncedSearch);
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  return (
    <FormItem title="Docente" type="instructors">
      <div className={css.content}>
        {!user?.abilities?.can_assign_instructors_from_other_courses ? (
          <Tooltip
            placement="topLeft"
            label="Su rol solo permite asignar docentes configurados para la asignatura"
          >
            <div>
              <Checkbox
                disabled
                className={css.instructorCheckbox}
                labelRight="Solo docentes configurados para la asignatura"
                checked
              />
            </div>
          </Tooltip>
        ) : (
          <Checkbox
            disabled={disabled}
            className={css.instructorCheckbox}
            labelRight="Solo docentes configurados para la asignatura"
            checked={shouldFilterInstructors}
            onChange={e => setShouldFilterInstructors(e.target.checked)}
          />
        )}

        <div className={css.item}>
          <Autocomplete
            disabled={someDeletedSessionIsSelected || disabled}
            loading={loading}
            options={options}
            className={cx(css.item_select)}
            formatOptionLabel={getFormattedLabel}
            setValueSearch={value => setValueSearch(value)}
          />
          <button
            disabled={someDeletedSessionIsSelected || disabled}
            onClick={() => {
              dispatch({
                type: Types.AdvanceSearchInstructor,
                payload: true,
              });
            }}
            className={css.item_link}
          >
            Búsqueda Avanzada
          </button>
        </div>
        <SimpleTableSearch disabled={someDeletedSessionIsSelected || disabled} />
      </div>
    </FormItem>
  );
};

export default InstructorSearch;
