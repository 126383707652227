import { ActionMap } from "@models/IContext";
import {
  GroupsManagerEnrollmentsOrderByFieldsEnum,
  GroupsManagerEnrollmentsSearchableFieldsEnum,
  GroupsManagerGroupChangesOrderByFieldsEnum,
  GroupsManagerGroupChangesSearchableFieldsEnum,
  GroupsManagerSearchOrderByFields,
  GroupsManagerSearchSearchableFieldsEnum,
  OrderByDirection,
} from "@models/ISchema";
import {
  EnrollmentsTableColumn,
  GroupsChangesTableColumn,
  GroupsManagerTableColumn,
  SubGroupsTableColumn,
} from "../models";
import {
  setAll,
  setColumnsToHide,
  setOrderBy,
  setSearchByFields,
  setSearchByText,
} from "./tableFilters.actions";
import { TableFiltersReducerType } from "./types";

export enum Types {
  ResetFilters = "RESET_FILTERS",
  SetAll = "SET_ALL",
  SetColumnsToHide = "SET_COLUMNS_TO_HIDE",
  SetOrderBy = "SET_ORDER_BY",
  SetSearchByFields = "SET_SEARCH_BY_FIELDS",
  SetSearchByText = "SET_SEARCH_BY_TEXT",
}

type TableFiltersPayload = {
  [Types.ResetFilters]: undefined;
  [Types.SetAll]: TableFiltersReducerType;
  [Types.SetColumnsToHide]: Set<GroupsManagerTableColumn>;
  [Types.SetOrderBy]: {
    header:
      | GroupsManagerTableColumn
      | GroupsChangesTableColumn
      | SubGroupsTableColumn
      | EnrollmentsTableColumn;
    field:
      | GroupsManagerSearchOrderByFields
      | GroupsManagerGroupChangesOrderByFieldsEnum
      | GroupsManagerEnrollmentsOrderByFieldsEnum;
    direction: OrderByDirection;
  };
  [Types.SetSearchByFields]:
    | GroupsManagerSearchSearchableFieldsEnum[]
    | GroupsManagerGroupChangesSearchableFieldsEnum[]
    | GroupsManagerEnrollmentsSearchableFieldsEnum[];
  [Types.SetSearchByText]: string;
};

export type TableFiltersActions = ActionMap<TableFiltersPayload>[keyof ActionMap<
  TableFiltersPayload
>];

export const tableFiltersReducer = (
  state: TableFiltersReducerType,
  action: TableFiltersActions,
) => {
  switch (action?.type) {
    case Types.ResetFilters:
      return {
        ...state,
        tableFilters: {
          columnsToHide: new Set<GroupsManagerTableColumn | GroupsChangesTableColumn>(),
          orderBy: {
            header: null,
            field: null,
            direction: null,
          },
          searchBy: {
            text: "",
            fields: [],
          },
        },
      };
    case Types.SetAll:
      return setAll(action?.payload, state);
    case Types.SetColumnsToHide:
      return setColumnsToHide(action?.payload, state);
    case Types.SetOrderBy:
      return setOrderBy(action?.payload, state);
    case Types.SetSearchByFields:
      return setSearchByFields(action?.payload, state);
    case Types.SetSearchByText:
      return setSearchByText(action?.payload, state);
    default:
      return state;
  }
};
