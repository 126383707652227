import gql from "graphql-tag";

export const groupEnrollmentsByGroupCode = gql`
  query groupEnrollmentsByGroupCode(
    $scenarioId: ID!
    $originId: ID!
    $groupCode: String
    $filter: GroupsManagerEnrollmentsFilterInputType!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groupEnrollments(groupCode: $groupCode, filter: $filter) {
        items {
          isWaitingList
          group {
            code
          }
          student {
            id
            code
            name
            firstName
            status
            meta
          }
          term {
            id
            code
            name
          }
          campus {
            id
            code
            name
          }
          program {
            id
            code
            name
          }
          enrollmentDate
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
        __typename
      }
      __typename
    }
  }
`;
