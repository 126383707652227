import React, { useContext, useEffect } from "react";
import { Input } from "@foris/avocado-ui";
import cx from "classnames";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/search.reducer";
import { Types as SavedRequestTypes } from "../../context/savedRequest.reducer";
import { lensPath, pipe, set } from "ramda";
import { Icon } from "@foris/avocado-icons";
import css from "./requirements.module.scss";

const Requirements = () => {
  const { state, dispatch } = useContext(BookingContext);
  let error = null;
  if (state?.search?.requirements?.people?.error) error = "Ingresa la capacidad requerida";

  useEffect(() => {
    const booking = state?.search?.currentBooking;
    const capacity = state?.savedRequest?.bookingCapacity;

    if (!booking) return;

    const payload = pipe(
      set(lensPath(["people", "count"]), capacity ?? booking?.classroom?.capacity),
      set(lensPath(["people", "error"]), false),
    )(state?.search?.requirements);

    dispatch({
      type: SavedRequestTypes.setSavedBookingCapacity,
      payload: null,
    });
    dispatch({ type: Types.SetRequirements, payload });
  }, []);

  return (
    <section className={cx(css.requirementsContent, "container-row", "col_12")}>
      <p className={cx(css.requirementsContent_text, "col_12")}>
        ¿Cuáles son tus requerimientos? *
      </p>
      <Input
        classname={{ global: cx(css.requirementsContent_inputPerson) }}
        type="number"
        label="Capacidad"
        error={error}
        value={state?.search?.requirements?.people?.count || ""}
        onWheel={e => e.currentTarget.blur()}
        onChange={value => {
          const cloneRequirements = { ...state?.search?.requirements };
          cloneRequirements.people.count = parseInt(value.target.value);
          cloneRequirements.people.error = false;
          dispatch({ type: Types.SetRequirements, payload: cloneRequirements });
        }}
      />

      <div className={css.requirementsContent_info}>
        <Icon className={css.infoIcon} name="circle-info" size="sm" filled />

        <p className={css.infoText}>
          <b className={css.infoText_important}>Aforo Requerido.</b> No se mostrarán salas
          disponibles con aforo inferior al valor aquí introducido.
        </p>
      </div>
    </section>
  );
};

export default Requirements;
