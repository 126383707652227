import React, { useState, useMemo, useEffect, useContext, useRef } from "react";
import {
  always,
  append,
  assoc,
  equals,
  filter,
  keys,
  length,
  lensProp,
  map,
  pipe,
  reduce,
  reject,
  set,
  toPairs,
  when,
} from "ramda";
import { Button, Icon, Switch, Select, Input, Checkbox } from "@foris/avocado-ui";
import { OrderByDirection } from "@models/ISchema";
import {
  EnrollmentsTableColumn,
  GroupsChangesTableColumn,
  GroupsManagerTableColumn,
  SubGroupsTableColumn,
} from "../../models";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import { Context } from "../../context/GroupsManagerContext";
import { TableFiltersReducerType } from "../../context/types";
import cx from "classnames";
import css from "./tableFilter.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

export interface TableFilterProps {
  selectableColumns:
    | GroupsChangesTableColumn[]
    | GroupsManagerTableColumn[]
    | SubGroupsTableColumn[]
    | EnrollmentsTableColumn[];
  columnsToSearchBy: Partial<Record<GroupsChangesTableColumn, boolean>>;
  hideColumnsToHideConfig?: boolean;
  headerToOrderByObjCallback?:
    | ((header: GroupsManagerTableColumn) => TableFiltersReducerType["orderBy"])
    | ((header: GroupsChangesTableColumn) => TableFiltersReducerType["orderBy"])
    | ((header: SubGroupsTableColumn) => TableFiltersReducerType["orderBy"])
    | ((header: EnrollmentsTableColumn) => TableFiltersReducerType["orderBy"]);
  request: (
    newOrderBy: TableFiltersReducerType["orderBy"],
    newSearchBy: TableFiltersReducerType["searchBy"],
  ) => void;
  setColumnsToSearchBy:
    | ((columnsToSearchBy: Partial<Record<GroupsChangesTableColumn, boolean>>) => void)
    | ((columnsToSearchBy: Partial<Record<GroupsManagerTableColumn, boolean>>) => void)
    | ((columnsToSearchBy: Partial<Record<EnrollmentsTableColumn, boolean>>) => void)
    | ((columnsToSearchBy: Partial<Record<SubGroupsTableColumn, boolean>>) => void);
  nonSortableColumns?: Set<string>;
  className?: string;
}

const TableFilter: React.FC<TableFilterProps> = ({
  className,
  columnsToSearchBy,
  setColumnsToSearchBy,
  nonSortableColumns = new Set(),
  selectableColumns,
  headerToOrderByObjCallback = () => {
    /* this comment avoids linter warning */
  },
  hideColumnsToHideConfig = false,
  request,
}) => {
  const { state, dispatch } = useContext(Context);
  const [localOrderBy, setLocalOrderBy] = useState<TableFiltersReducerType["orderBy"]>(
    state?.tableFilters?.orderBy,
  );
  const [displayColumnsToHideConfig, setDisplayColumnsToHideConfig] = useState(false);
  const [displayColumnsToSearchBy, setDisplayColumnsToSearchBy] = useState(false);
  const [columnsToHide, setColumnsToHide] = useState<
    Set<GroupsChangesTableColumn | GroupsManagerTableColumn | EnrollmentsTableColumn>
  >(state?.tableFilters?.columnsToHide);
  const [localColumnsToSearchBy, setLocalColumnsToSearchBy] = useState<
    Partial<
      Record<GroupsChangesTableColumn | GroupsManagerTableColumn | EnrollmentsTableColumn, boolean>
    >
  >(columnsToSearchBy);

  const [selectedColumn, setSelectedColumn] = useState({ label: null, value: null });
  const selectableOptions = useMemo(
    () => map(column => ({ label: column, value: column }), selectableColumns),
    [selectableColumns],
  );
  const todoIsChecked = useMemo(
    () =>
      pipe(
        toPairs,
        filter(([, checked]) => checked),
        length,
        equals(length(keys(localColumnsToSearchBy))),
      )(localColumnsToSearchBy),
    [localColumnsToSearchBy],
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    setSelectedColumn({
      label: state?.tableFilters?.orderBy?.header,
      value: state?.tableFilters?.orderBy?.header,
    });
  }, [state?.tableFilters?.orderBy]);

  useClickOutside({
    ref: wrapperRef,
    onClickOutside: () => {
      setDisplayColumnsToSearchBy(false);
    },
  });

  useEffect(() => {
    if (!selectedColumn?.value) return;
    const newLocalOrderBy = set(
      lensProp("direction"),
      state?.tableFilters?.orderBy?.direction,
      (headerToOrderByObjCallback as any)(selectedColumn?.value),
    );
    setLocalOrderBy(newLocalOrderBy);
  }, [selectedColumn]);

  const updateColumnsToHide = (
    columnToToggle:
      | GroupsChangesTableColumn
      | GroupsManagerTableColumn
      | SubGroupsTableColumn
      | EnrollmentsTableColumn,
  ) => {
    if (columnsToHide.has(columnToToggle)) {
      columnsToHide.delete(columnToToggle);
    } else {
      columnsToHide.add(columnToToggle);
    }
    setColumnsToHide(new Set(columnsToHide));
  };

  const setAllColumnsToSearchBy = (valueToSet: boolean) => {
    const newColumnsToSearchBy = reduce(
      (acc, column) => assoc(column, valueToSet, acc),
      {},
      keys(localColumnsToSearchBy),
    );
    setLocalColumnsToSearchBy(newColumnsToSearchBy);
  };

  const handleResetSearchFilters = () => {
    setAllColumnsToSearchBy(true);
    setDisplayColumnsToSearchBy(false);
    dispatch({
      type: TableFiltersTypes.SetSearchByText,
      payload: "",
    });
    dispatch({
      type: TableFiltersTypes.SetSearchByFields,
      payload: [],
    });

    request(localOrderBy, {
      fields: [],
      text: "",
    });
  };

  return (
    <section className={cx(css.filter, className)}>
      <div className={css.textSearch}>
        <Input
          classname={{ global: css.textSearch_input }}
          value={state?.tableFilters?.searchBy?.text}
          onChange={(event: any) => {
            dispatch({ type: TableFiltersTypes.SetSearchByText, payload: event?.target?.value });
          }}
          placeholder="Buscar en tabla"
          type="text"
        />
        <button
          className={css.textSearch_button}
          onClick={() => {
            setDisplayColumnsToSearchBy(!displayColumnsToSearchBy);
            setDisplayColumnsToHideConfig(false);
          }}
        >
          Buscar en
          <Icon icon="chevron-down" size={14} />
        </button>
      </div>
      {!hideColumnsToHideConfig && (
        <div>
          <Button
            className={css.textSearch_ghostButton}
            color="primary"
            variant="ghost"
            onClick={() => {
              setDisplayColumnsToHideConfig(!displayColumnsToHideConfig);
              setDisplayColumnsToSearchBy(false);
            }}
          >
            <Icon icon="filter" size={14} />
            Filtrar
          </Button>
        </div>
      )}

      {displayColumnsToSearchBy && (
        <section
          ref={wrapperRef}
          className={css.columnsToSearchBy}
          style={{
            marginRight: hideColumnsToHideConfig && "0",
          }}
        >
          <div className={css.columnsToSearchBy_container}>
            <Checkbox
              checked={todoIsChecked}
              labelRight="Todo"
              onChange={() => setAllColumnsToSearchBy(!todoIsChecked)}
            />

            <hr className={css.columnsToSearchBy_line} />

            {Object.entries(localColumnsToSearchBy).map(([column, checked]) => (
              <Checkbox
                className={css.columnsToSearchBy_checkbox}
                key={column}
                checked={checked as boolean}
                labelRight={column}
                onChange={() => {
                  setLocalColumnsToSearchBy(
                    set(
                      lensProp(column as GroupsChangesTableColumn | GroupsManagerTableColumn),
                      !checked,
                      localColumnsToSearchBy,
                    ),
                  );
                }}
              />
            ))}
          </div>

          <div className={css.buttons}>
            <Button
              className={css.buttons_button}
              color="primary"
              variant="outline"
              onClick={handleResetSearchFilters}
            >
              Cancelar
            </Button>
            <Button
              className={css.buttons_button}
              color="primary"
              disabled={
                state?.tableFilters?.searchBy?.text === "" ||
                state?.tableFilters?.searchBy?.text == null
              }
              onClick={() => {
                const fields = reduce(
                  (acc, [column, shouldBeUsed]) =>
                    when(
                      always(shouldBeUsed),
                      append((headerToOrderByObjCallback as any)(column)?.field),
                      acc,
                    ),
                  [],
                  toPairs(localColumnsToSearchBy),
                );
                setColumnsToSearchBy(localColumnsToSearchBy);
                dispatch({
                  type: TableFiltersTypes.SetSearchByFields,
                  payload: fields,
                });
                setDisplayColumnsToSearchBy(false);
                request(localOrderBy, {
                  fields,
                  text: state?.tableFilters?.searchBy?.text,
                });
              }}
            >
              Buscar
            </Button>
          </div>
        </section>
      )}

      {displayColumnsToHideConfig && (
        <section className={css.columnsToHideConfig}>
          {/* Title */}
          <span className={css.columnsToHideConfig_header}>Filtros</span>

          <hr className={css.columnsToHideConfig_line} />

          {/* Sort results */}
          <div className={css.columns}>
            <span className={css.columns_header}>Ocultar columna</span>
            {selectableColumns?.map(
              (
                columnHeader:
                  | GroupsChangesTableColumn
                  | GroupsManagerTableColumn
                  | EnrollmentsTableColumn,
              ) => (
                <Switch
                  key={columnHeader}
                  className={css.columns_column}
                  labelRight={columnHeader}
                  checked={columnsToHide.has(columnHeader)}
                  onChange={() =>
                    updateColumnsToHide(
                      columnHeader as
                        | GroupsChangesTableColumn
                        | GroupsManagerTableColumn
                        | EnrollmentsTableColumn,
                    )
                  }
                />
              ),
            )}
          </div>

          <hr className={css.columnsToHideConfig_line} />

          {/* Sort results */}
          <div className={css.columnToOrderBy}>
            <span className={css.columnToOrderBy_header}>Ordenar resultados</span>
            <div className={css.columnToOrderBy_selector}>
              <Select
                className={css.selector_select}
                options={reject(
                  (column: { value: string; label: string }) =>
                    nonSortableColumns.has(column?.value),
                  selectableOptions,
                )}
                value={selectedColumn}
                onChange={setSelectedColumn}
              />
              <button
                className={css.selector_directionButton}
                onClick={() => {
                  const newDirection =
                    localOrderBy?.direction === OrderByDirection.Desc
                      ? OrderByDirection.Asc
                      : OrderByDirection.Desc;
                  setLocalOrderBy(set(lensProp("direction"), newDirection, localOrderBy));
                }}
              >
                <Icon
                  icon={
                    localOrderBy?.direction === OrderByDirection.Desc ? "arrow-down" : "arrow-up"
                  }
                  size={18}
                />
              </button>
            </div>
          </div>

          <div className={css.buttons}>
            <Button
              className={css.buttons_button}
              color="primary"
              variant="outline"
              onClick={() => {
                setColumnsToHide(new Set());
                setDisplayColumnsToHideConfig(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              className={css.buttons_button}
              color="primary"
              onClick={() => {
                // external changes
                dispatch({
                  type: TableFiltersTypes.SetColumnsToHide,
                  payload: columnsToHide as any,
                });
                dispatch({
                  type: TableFiltersTypes.SetOrderBy,
                  payload: localOrderBy,
                });

                // local changes
                setColumnsToHide(new Set(columnsToHide));
                setDisplayColumnsToHideConfig(false);

                // request if necessary
                if (
                  localOrderBy?.header !== state?.tableFilters?.orderBy?.header ||
                  localOrderBy?.direction !== state?.tableFilters?.orderBy?.direction
                ) {
                  request(localOrderBy, state?.tableFilters?.searchBy);
                }
              }}
            >
              Aplicar Filtros ({columnsToHide.size})
            </Button>
          </div>
        </section>
      )}
    </section>
  );
};

export default TableFilter;
