import React, { useContext, useState } from "react";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { Button, DataGrid } from "@foris/avocado-ui";
import { OrderByDirection, SessionLogEntry } from "@models/ISchema";
import { Link } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import cx from "classnames";
import dayjs from "dayjs";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import TableFilter from "@modules/sections/GroupsManager/components/TableFilter/TableFilter";
import { sessionChangesTableHeaderToOrderByObj } from "@modules/session/utils/historyTableUtils";
import { Context } from "@modules/sections/GroupsManager/context/GroupsManagerContext";
import { Column, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import { Types as TableFiltersTypes } from "@modules/sections/GroupsManager/context/tableFilters.reducer";
import styles from "./sessionHistoryTable.module.scss";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";

interface SessionHistoryTableProps {
  data: SessionLogEntry[];
  pagination?: PagerProps;
  onLoadLogEntries?: (
    page: number,
    newOrderBy: {
      header: string;
      field: string;
      direction: OrderByDirection;
    },
    searchBy: {
      fields: string[];
      text: string;
    },
  ) => void;
}

const SessionHistoryTable = ({
  data = [],
  onLoadLogEntries,
  pagination,
}: SessionHistoryTableProps) => {
  const { workspace, origin, scenario }: IParams = useParams();
  const { state, dispatch } = useContext(Context);
  const [columnsToSearchBy, setColumnsToSearchBy] = useState({
    Fecha: true,
    Liga: true,
    "Sesión ID": true,
    Responsable: true,
  });

  const columns: DataGridProps<SessionLogEntry>["columns"] = [
    {
      header: "Fecha",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return (
          <span className={cx(styles.tableCol, styles.tableCol__rightGap)}>
            {sessionLogEntry?.date}
          </span>
        );
      },
      styles: {
        minWidth: "190px",
      },
    },
    {
      header: "Liga",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return (
          <Link
            href={`/scheduler/editor/link/${workspace}/${scenario}/${origin}/${sessionLogEntry?.link?.id}`}
            className={cx(styles.tableCol, styles.tableCol__link, styles.tableCol__rightGap)}
          >
            {sessionLogEntry?.link?.code}
          </Link>
        );
      },
      styles: {
        minWidth: "110px",
      },
    },
    {
      header: "Sesión ID",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return (
          <span className={cx(styles.tableCol, styles.tableCol__rightGap)}>
            {sessionLogEntry?.session?.id ?? "-"}
          </span>
        );
      },
      styles: {
        minWidth: "110px",
      },
    },
    {
      header: "Tipo de edición",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return (
          <ul
            className={cx(styles.tableCol, styles.tableCol__listShort, styles.tableCol__rightGap)}
          >
            {sessionLogEntry?.changeType?.map((changeType, index) => (
              <li key={index}>{changeType}</li>
            ))}
          </ul>
        );
      },
      styles: {
        minWidth: "190px",
      },
    },
    {
      header: "Acción",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return (
          <ul className={cx(styles.tableCol, styles.tableCol__list, styles.tableCol__rightGap)}>
            {sessionLogEntry?.change?.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
        );
      },
      styles: {
        minWidth: "290px",
      },
    },
    {
      header: "Responsable",
      renderer: (sessionLogEntry: SessionLogEntry) => {
        return sessionLogEntry?.user ? (
          <div className={styles.tableCol}>
            <Icon name="user" />
            <span>
              {sessionLogEntry?.user?.id} {sessionLogEntry?.user?.name}
            </span>
          </div>
        ) : (
          "-"
        );
      },
      styles: {
        minWidth: "160px",
      },
    },
  ];

  const renderLeftHeader = () => {
    const now = dayjs().format("DD/MM/YYYY HH:mm");

    return (
      <div className={styles.tableLeftHeader}>
        <span className={styles.tableLeftHeader_total}>{pagination?.total ?? 0} Sesiones</span>
        <span className={styles.tableLeftHeader_separator}></span>
        <span className={styles.tableLeftHeader_updatedAt}>
          <strong className={styles.updatedAtLabel}>Última actualización</strong>: {now}
        </span>
      </div>
    );
  };

  const renderMiddleHeader = () => {
    return (
      <Button
        className={styles.tableMiddleAction}
        color="primary"
        variant="outline"
        onClick={() =>
          onLoadLogEntries?.(
            pagination?.page ?? 1,
            state?.tableFilters?.orderBy,
            state?.tableFilters?.searchBy,
          )
        }
      >
        <Icon name="repeat" size="md" />
        Actualizar vista
      </Button>
    );
  };

  const onHeaderClick = (column: Column<SessionLogEntry>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;
    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;

      const newOrderBy = {
        ...currentOrderBy,
        direction: newDirection,
      };

      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      onLoadLogEntries?.(pagination?.page, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = sessionChangesTableHeaderToOrderByObj(column?.header);
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy as any,
      });
      onLoadLogEntries?.(pagination?.page, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  return (
    <div className={styles.sessionHistoryTable}>
      <DataGrid
        className={styles.sessionHistoryTable_table}
        columns={columns}
        nonSortableColumns={new Set(["Acción"])}
        batch={data}
        columnsToHide={state?.tableFilters?.columnsToHide}
        onHeaderClick={onHeaderClick}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        pagination={{
          page: pagination?.page ?? 1,
          total: pagination?.total ?? 0,
          size: pagination?.size ?? 10,
          onPageChange: (page: number) => {
            onLoadLogEntries?.(page, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
          },
        }}
        header={{
          className: styles.tableHeader,
          left: renderLeftHeader(),
          middle: renderMiddleHeader(),
          right: (
            <TableFilter
              columnsToSearchBy={columnsToSearchBy}
              nonSortableColumns={new Set(["Acción"])}
              setColumnsToSearchBy={setColumnsToSearchBy as any}
              headerToOrderByObjCallback={sessionChangesTableHeaderToOrderByObj as any}
              selectableColumns={
                columns?.map(col => col.header)?.filter(col => col !== "Acción") as any
              }
              request={(
                newOrderBy: {
                  header: string;
                  field: string;
                  direction: OrderByDirection;
                },
                newSearchBy: {
                  fields: string[];
                  text: string;
                },
              ) => {
                onLoadLogEntries?.(1, newOrderBy, newSearchBy);
              }}
            />
          ),
        }}
      />
    </div>
  );
};

export default SessionHistoryTable;
