import React from "react";
import { Route, Switch } from "react-router-dom";
import EditorHome from "../../modules/home/Home";
import AdvanceSearch from "../../modules/advance-search/AdvanceSearch";
import Vacancies from "../../modules/vacancies/Vacancies";
import CreateSection from "../../modules/dimensions/section/CreateSection/CreateSection";
import EditSessions from "../../modules/sections/EditSessions/pages/EditSessions";
import Packages from "../../modules/sections/Packages/pages/Packages";
import PackageCreation from "@modules/sections/Packages/pages/PackageCreation";
import SessionsDelete from "@modules/holidays/pages/SessionsDelete";
import ClientCodeManagement from "@dimensions/link/pages/ClientCodeManagement";
import SessionChangeHistory from "@modules/session/pages/session-change-history/SessionChangeHistory";
import { ContextProvider } from "@modules/sections/GroupsManager/context/GroupsManagerContext";

export const base = "/editor";
const context = ":workspace/:scenario/:origin";
const dimension = ":dimension";

const moduleRoutes = [
  {
    path: `${base}/session/holidays/${context}`,
    component: SessionsDelete,
    name: "HolidayDeleteSessions",
    isProtected: false,
  },
  {
    path: `${base}/session/change-history/${context}`,
    component: () => (
      <ContextProvider>
        <SessionChangeHistory />
      </ContextProvider>
    ),
    name: "SessionChangeHistory",
    isProtected: false,
  },
  {
    path: `${base}/advanceSearch/${dimension}/${context}`,
    component: AdvanceSearch,
    name: "AdvanceSearchDimension",
    isProtected: false,
  },
  {
    path: `${base}/advanceSearch/${context}`,
    component: AdvanceSearch,
    name: "AdvanceSearchDimension",
    isProtected: false,
  },
  {
    path: `${base}/vacancies/${context}/:id`,
    component: Vacancies,
    name: "Vacancies",
    isProtected: false,
  },
  {
    path: `${base}/link/client-code/${context}/:id`,
    component: ClientCodeManagement,
    name: "LinkClientCode",
  },
  {
    path: `${base}/create-section/${context}`,
    component: CreateSection,
    name: "CreateSection",
    isProtected: false,
  },
  {
    path: `${base}/form-edit/${context}/:id`,
    component: EditSessions,
    name: "EditSessions",
    isProtected: false,
  },
  {
    path: `${base}/packages-edit/${context}/:id`,
    component: Packages,
    name: "Packages",
    isProtected: false,
  },
  {
    path: `${base}/create-package/${context}`,
    component: PackageCreation,
    name: "PackagesCreation",
    isProtected: false,
  },
  {
    path: `${base}`,
    component: EditorHome,
    name: "EditorHome",
    isProtected: false,
  },
];

const RouteEditor: React.FC = () => {
  return (
    <Switch>
      {moduleRoutes.map(route => (
        <Route key={route.path} path={route.path} component={route.component} />
      ))}
    </Switch>
  );
};

export default RouteEditor;
