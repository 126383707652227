import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loading, Snackbar } from "@foris/avocado-ui";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { IParams } from "../../../../models/IParams";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import useGetPackageSection from "../hooks/useGetPackageSection";
import Tags from "@dimensions/group/GridSections/Tags/Tags";
import Menu, { MenuItem } from "@common/components/Header/Menu/Menu";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import usePackageUpdate from "../hooks/usePackageUpdate";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import DeletePackageModal from "../components/DeletePackageModal";
import PackageHeaderSubtitle from "../components/PackageHeaderSubtitle";
import useGetPackageSimpleGroups from "../hooks/useGetPackageSimpleGroups";
import css from "./package.module.scss";
import { FiltersType } from "@modules/sections/GroupsManager/models";

const isValidHost = (optionalHosts = ["tecpprd-inscripciones", "tec-inscripciones"]) => {
  if (!window?.location?.host) return false;

  const host = window?.location?.host?.split(".")?.[0] || "";
  const hostsToValidate = [...optionalHosts, "localhost", "giannina"];
  const regex = new RegExp(hostsToValidate.join("|"), "i");

  return regex.test(host);
};

const PackageCalendar: React.FC = () => {
  const { id, origin, scenario, workspace }: IParams = useParams();
  const context: IContextApp = useContext(ContextApp);
  const history = useHistory();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const [showPackageModal, setShowPackageModal] = useState(false);
  const [showReplicatePackageModal, setShowReplicatePackageModal] = useState(false);
  const [showReplicationError, setShowReplicationError] = useState(false);
  const [showReplicationSuccess, setShowReplicationSuccess] = useState(false);
  const [showDeletePackageModal, setShowDeletePackageModal] = useState(false);
  const [isReplicatingPackage, setIsReplicatingPackage] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);

  const editLabelsRoute = `/scheduler/editor/package/edit-labels/${workspace}/${scenario}/${origin}/${id}`;
  const userCanEditPackages = context?.user?.abilities?.can_edit_packages;

  const [dimension] = useGetDimension(Dimension.package);
  const {
    isLoading,
    hasError,
    sections,
    setSections,
    headerData,
    loadPackageData,
    packageData,
  } = useGetPackageSection({
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    workspaceId: workspace,
  });

  const { getPackageSubgroups, groups } = useGetPackageSimpleGroups({
    packageId: id,
    scenarioId: scenario,
    originId: origin,
  });

  const headerDataAdapted = useMemo(() => {
    const totalCapacityDetails = {
      title: "Vacantes Totales",
      content: "0",
    };
    const availableCapacityDetails = {
      title: "Vacantes Disponibles",
      content: "0",
    };

    if (groups?.length && packageData?.isPublished) {
      const packageTypeGroups = groups?.filter(({ referentType }) => referentType === "PACKAGE");
      const capacityList = packageTypeGroups?.map(group => group?.capacity);
      const availableCapacityList = packageTypeGroups?.map(
        group => (group?.capacity ?? 0) - (group?.enrollmentStats?.usedCapacity ?? 0),
      );

      const totalCapacity = Math.min(...capacityList);
      const availableCapacity = Math.min(...availableCapacityList);

      totalCapacityDetails.content = totalCapacity.toString();
      availableCapacityDetails.content = availableCapacity.toString();
    }

    return {
      ...headerData,
      moreDetails: packageData?.isPublished
        ? [totalCapacityDetails, availableCapacityDetails]
        : headerData?.moreDetails,
    };
  }, [headerData, groups]);

  useEffect(() => {
    if (packageData?.isPublished) {
      getPackageSubgroups();
    }
  }, [packageData?.isPublished]);

  const {
    data: { isLoading: isUpdatingPackage, response },
    handleUpdatePackage,
    handleDeletePackage,
  } = usePackageUpdate({
    originId: origin,
    scenarioId: scenario,
    onSuccess: loadPackageData,
    onErrorDeleting() {
      setShowDeleteError(true);
    },
    onDeleted() {
      history.replace(`/home/${workspace}/${scenario}/${origin}`);
    },
  });

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setSections(data);
    callback && callback();
  };

  useEffect(() => {
    if (isReplicatingPackage && !isUpdatingPackage && !isLoading) {
      setIsReplicatingPackage(false);

      if (!response?.commited || !response?.payload?.updates[0]?.replicatedPackages?.length) {
        setShowReplicationError(true);
      }

      if (response?.commited && response?.payload?.updates[0]?.replicatedPackages?.length) {
        setShowReplicationSuccess(true);
      }
    }
  }, [isUpdatingPackage, isLoading]);

  useEffect(() => {
    if (!sections && !hasError) loadPackageData();
  }, [sections, hasError]);

  const handleGroupsManagerClick = () => {
    history.push(`/editor/groups-manager/${contextUrl}`, {
      filter: FiltersType.PACKAGE,
      data: packageData,
    });
  };

  const getMenuOptions = (): MenuItem[] => {
    const menuOptions = [];
    const hasClashLabel = packageData?.labels?.some(label =>
      ["CLSHP", "package_clash"].includes(label?.code),
    );

    if (context?.user?.permissions?.update && context?.user?.abilities?.can_edit_assignment) {
      menuOptions.push({
        label: "Gestionar ligas",
        icon: "edit",
        onClick: () =>
          history.push(
            `/scheduler/editor/package/links-management/${workspace}/${scenario}/${origin}/${id}`,
          ),
      });
    }

    if (isValidHost()) {
      if (!packageData?.isPublished && !hasClashLabel) {
        menuOptions.push({
          label: "Publicar Paquete",
          icon: "arrow-up",
          onClick: () => setShowPackageModal(true),
        });
      }

      if (packageData?.isPublished && !hasClashLabel) {
        menuOptions.push({
          label: "Cambiar visibilidad",
          icon: "open-eye",
          onClick: () =>
            handleUpdatePackage({
              packageId: packageData?.id,
              isVisible: !packageData?.isVisible,
            }),
        });
      }

      if (packageData?.isReplicated === null && !packageData?.isPublished) {
        menuOptions.push({
          label: "Multiplicar paquete",
          icon: "copy",
          onClick: () => setShowReplicatePackageModal(true),
        });
      }
    }

    if (context?.user?.abilities?.can_delete_packages) {
      menuOptions.push({
        label: "Eliminar Paquete",
        icon: "trash",
        disabled: packageData?.isPublished,
        tooltipLabel: packageData?.isPublished ? "Paquete publicado no puede ser eliminado" : "",
        onClick: packageData?.isPublished ? () => null : () => setShowDeletePackageModal(true),
      });
    }

    if (context?.user?.abilities?.can_access_group_manager) {
      menuOptions.push({
        label: "Ir a Gestión de Grupos",
        onClick: () => handleGroupsManagerClick(),
      });
    }

    return menuOptions;
  };

  const handlePublishPackage = () => {
    setShowPackageModal(false);

    handleUpdatePackage({
      packageId: packageData?.id,
      isPublished: true,
    });
  };

  const handleReplicatePackage = () => {
    setShowReplicatePackageModal(false);
    setIsReplicatingPackage(true);

    handleUpdatePackage({
      packageId: packageData?.id,
      replicated: true,
    });
  };

  const handleCloseReplicationSnackbar = () => {
    setShowReplicationError(false);
    setShowReplicationSuccess(false);
  };

  const handleDeletePackageRequest = () => {
    setShowDeletePackageModal(false);
    handleDeletePackage({ packageId: packageData?.id });
  };

  return (
    <Layout contextSearch>
      <Snackbar
        type="error"
        setValueActive={() => setShowDeleteError(false)}
        active={showDeleteError}
        icon="circle-full-alert"
        duration={5}
      >
        No cuentas con los permisos necesarios para eliminar este paquete.
      </Snackbar>

      <Snackbar
        type={showReplicationError ? "error" : "confirm"}
        setValueActive={handleCloseReplicationSnackbar}
        active={showReplicationError || showReplicationSuccess}
        icon={showReplicationError ? "circle-full-alert" : "check"}
        duration={8}
      >
        {showReplicationError
          ? "No hay paquetes a replicar"
          : `Replicación Exitosa. Paquetes Generados: ${response?.payload?.updates[0]?.replicatedPackages?.length}`}
      </Snackbar>

      {headerDataAdapted && (
        <Header
          dimension={dimension.label}
          {...headerDataAdapted}
          renderMenu={() =>
            userCanEditPackages && !!getMenuOptions()?.length ? (
              <Menu items={getMenuOptions()} />
            ) : null
          }
          subTitle={<PackageHeaderSubtitle packageData={packageData} />}
        >
          <div className={css.labelsWrapper}>
            {headerDataAdapted?.systemLabels?.length > 0 && (
              <Tags
                sectionTitle="Etiquetas automáticas"
                labels={headerDataAdapted?.systemLabels}
                addMargin={false}
                canEdit={false}
              />
            )}
            <Tags
              sectionTitle="Etiquetas manuales"
              route={editLabelsRoute}
              labels={headerDataAdapted?.labels}
              addMargin={false}
            />
          </div>
        </Header>
      )}

      {(isLoading || isUpdatingPackage) && <Loading />}

      {hasError && <EmptyResults dimension="Paquete" />}

      {sections && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          defaultTerm={packageData?.population?.term}
          data={sections}
          setDataGrid={setDataCalendar}
        />
      )}

      <Modal
        typeState="confirm"
        show={showReplicatePackageModal}
        title="Multiplicar paquete"
        subtitle="Esta acción es irreversible."
        onClose={() => setShowReplicatePackageModal(false)}
        textButtonPrincipal="Multiplicar paquete"
        textButtonSecondary="Cancelar"
        onClickPrincipal={handleReplicatePackage}
        onClickSecondary={() => setShowReplicatePackageModal(false)}
      >
        El paquete se multiplicará para cada una de las carreras que contengan el mismo currículo y
        se lleva a cabo en la población asociada al paquete original, dentro de la misma Sede.
      </Modal>

      <Modal
        typeState="confirm"
        show={showPackageModal}
        title="¿Estás seguro de publicar el paquete?"
        subtitle="Esta acción es irreversible."
        onClose={() => setShowPackageModal(false)}
        textButtonPrincipal="Publicar paquete"
        textButtonSecondary="Cancelar"
        onClickPrincipal={handlePublishPackage}
        onClickSecondary={() => setShowPackageModal(false)}
      >
        Al publicar el paquete quedará activo para inscripción y no podrá ser modificado ni
        desactivado.
      </Modal>

      <DeletePackageModal
        isOpen={showDeletePackageModal}
        onConfirm={handleDeletePackageRequest}
        onCancel={() => setShowDeletePackageModal(false)}
      />
    </Layout>
  );
};

export default PackageCalendar;
