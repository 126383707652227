import React, { useState, useContext } from "react";
import { Select } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/formData.reducer";

interface AutocomleteProps {
  options: any;
  loading: boolean;
  disabled?: boolean;
  className: string;
  setValueSearch: (value: any) => void;
  formatOptionLabel?: (option: any) => any;
}

const AutocomleteSearch = ({
  options,
  loading,
  className,
  setValueSearch,
  disabled,
  formatOptionLabel,
}: AutocomleteProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [value, setValue] = useState(null);

  return (
    <Select
      isClearable
      isDisabled={disabled}
      isLoading={loading}
      options={options}
      value={value}
      isOptionDisabled={option => !!(option as any)?.isDisabled}
      formatOptionLabel={formatOptionLabel}
      className={className}
      placeholder="Escriba nombre o código de una sala"
      onInputChange={value => setValueSearch(value)}
      onChange={(value: any) => {
        const disabled = value
          ? state?.form?.editedSessions?.classrooms?.find(item => item.id === value.value)
          : true;
        if (!disabled) {
          const classroomList = state?.form?.editedSessions?.classrooms
            ? [...state?.form?.editedSessions?.classrooms]
            : [];
          classroomList.push(value);
          dispatch({
            type: Types.ClassroomEditedSessions,
            payload: {
              classrooms: classroomList,
            },
          });
        }
        setValue(null);
        /*

         */
      }}
    />
  );
};

export default AutocomleteSearch;
